import dynamic from "next/dynamic"
import React, { Fragment } from "react"

/** SSRさせないコンポーネント
 *
 * ```jsx
 * <NoSSR><SomeComponent/></NoSSR>
 * ```
 */
export const NoSSR = dynamic(() => Promise.resolve(NoSsrInner), {
  ssr: false,
})

const NoSsrInner: React.FC<React.PropsWithChildren<{}>> = (props) => (
  <Fragment>{props.children}</Fragment>
)
