import React from "react"
import { useIntersectionObserver } from "usehooks-ts"

/** ターゲットとなる要素が画面に表示されているかを取得するためのカスタムフック
 *
 * @returns ターゲットとなる要素に渡すrefとターゲットとなる要素が画面に表示されているかを表す真偽値
 */
export const useIsDisplayed = (): {
  targetRef: React.MutableRefObject<HTMLElement | null>
  isDisplayed: boolean
} => {
  const targetRef = React.useRef<HTMLElement | null>(null)
  const entry = useIntersectionObserver(targetRef, {})
  const isDisplayed = !!entry?.isIntersecting

  return { targetRef, isDisplayed }
}
