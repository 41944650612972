import {
  staticPath_assets_illust_goal_after_png,
  staticPath_assets_illust_concierge_three_contents_2_svg,
  staticPath_assets_illust_toshin_desc_3_png,
} from "lib/$asset-path"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Button, chakra, SimpleGrid, VStack } from "@chakra-ui/react"
import { OwataLabel } from "components/lp/widgets/OwataLabel"
import { Supervisor } from "components/lp/widgets/Supervisor"
import {
  NextImage,
  NextImageInlineWrapper,
  NextImageProps,
  Wbr,
} from "components/system"
import { izumida } from "data/omp-members"
import { pagesPath } from "lib/$path"

import NextLink from "next/link"
import React from "react"

export type WhatYouCanDoProps = {}

export const WhatYouCanDo = React.forwardRef<HTMLElement>(
  ({ ...props }: WhatYouCanDoProps, ref) => {
    return (
      <chakra.section ref={ref}>
        <VStack
          spacing={4}
          bgColor="moneiro-color-fill-pale-yellow"
          px={{ base: 3, md: 0 }}
          py={{ base: 6, md: 10 }}
        >
          <chakra.h2
            id="what-you-can-do"
            textStyle={{ base: "18-bold", md: "24-bold" }}
            textAlign="center"
            color="moneiro-color-text-black"
            aria-label="プロだけが知っていた資産運用のコツをあなたに。投資家やFPの知識をオンラインで簡単にすべて無料で使えます。"
          >
            プロだけが知っていた<Wbr>資産運用のコツをあなたに</Wbr>
            <chakra.div textStyle="14-reg" mt={2}>
              投資家やFPの知識をオンラインで簡単に
            </chakra.div>
            <chakra.div textStyle="14-bold" color="moneiro-color-main-orange">
              すべて無料で使えます
            </chakra.div>
          </chakra.h2>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            gap={4}
            w="full"
            maxW="container.content"
          >
            {DATA.map((data) => {
              return <Card {...data} key={data.sectionKey} />
            })}
          </SimpleGrid>
          <VStack spacing={2}>
            <OwataLabel
              labelProps={{
                textStyle: "14-bold",
              }}
              handProps={{
                w: "20px",
              }}
            >
              すべて、金融の専門家が監修しています
            </OwataLabel>
            <Supervisor
              supervisor={izumida}
              context="コンテンツ監修者"
              align="center"
              bgColor="moneiro-color-white"
              p={4}
              borderRadius="m"
            />
          </VStack>
        </VStack>
      </chakra.section>
    )
  }
)
WhatYouCanDo.displayName = "WhatYouCanDo"

export const Card = ({
  imgSrc,
  imgW,
  imgH,
  title,
  desc,
  btnHref,
  btnText,
  sectionKey: key,
}: Data) => (
  <VStack
    spacing={2}
    justify="space-between"
    bgColor="moneiro-color-white"
    px={2}
    py={6}
    borderRadius="m"
  >
    <NextImageInlineWrapper w={imgW} h={imgH} pos="relative">
      <NextImage src={imgSrc} layout="fill" objectFit="contain" alt="" />
    </NextImageInlineWrapper>
    <VStack spacing={{ base: 4, md: 5 }} w="full">
      <chakra.div>
        <chakra.p
          textStyle={"18-bold"}
          color="moneiro-color-main-orange"
          textAlign="center"
          id={`what-you-can-do-${key}`}
        >
          {title}
        </chakra.p>
        <chakra.p textStyle={{ base: "14-reg" }} textAlign="center">
          {desc}
        </chakra.p>
      </chakra.div>
      <NextLink href={btnHref} passHref legacyBehavior>
        <Button
          as="a"
          colorScheme="secondary"
          justifyContent="space-between"
          fontSize={{ base: "16px", md: "14px" }}
          leftIcon={<Box boxSize={{ base: "24px", md: 0 }} />}
          rightIcon={<ChevronRightIcon boxSize="24px" />}
          aria-labelledby={`what-you-can-do-${key}`}
          w="full"
          h={{ base: "50px", md: "35px" }}
        >
          {btnText}
        </Button>
      </NextLink>
    </VStack>
  </VStack>
)

type Data = {
  sectionKey: string
  imgSrc: string
  imgW: NextImageProps["w"]
  imgH: NextImageProps["h"]
  title: React.ReactNode
  desc: React.ReactNode
  btnText: string
  btnHref: string
}

const DATA: Data[] = [
  {
    sectionKey: "3min",
    imgSrc: staticPath_assets_illust_goal_after_png,
    imgW: { base: 315, md: 205 },
    imgH: { base: 190, md: 145 },
    title: <>3分投資診断</>,
    desc: (
      <>
        将来の必要金額と、<Wbr>最適な投資がわかる！</Wbr>
      </>
    ),
    btnText: "診断を始める",
    btnHref: pagesPath.simulation.$3min.$url().pathname,
  },

  {
    sectionKey: "consulting",
    imgSrc: staticPath_assets_illust_concierge_three_contents_2_svg,
    imgW: { base: 315, md: 205 },
    imgH: { base: 190, md: 125 },
    title: <>無料オンライン相談</>,
    desc: (
      <>
        お金のプロが、<Wbr>わかりやすくアドバイス！</Wbr>
      </>
    ),
    btnText: "詳細はこちら",
    btnHref: pagesPath.consulting.$url().pathname,
  },
  {
    sectionKey: "seminar",
    imgSrc: staticPath_assets_illust_toshin_desc_3_png,
    imgW: { base: 215, md: 185 },
    imgH: { base: 190, md: 125 },
    title: <>無料オンラインセミナー</>,
    desc: (
      <>
        毎日開催。<Wbr>お金の基本をわかりやすく解説</Wbr>
      </>
    ),
    btnText: "おすすめセミナーを見る",
    btnHref: pagesPath.money_seminar.$url().pathname,
  },
]
