import {
  Button,
  chakra,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  VStack,
} from "@chakra-ui/react"
import { useResponsiveValue } from "@~/common/components/system"
import { imgixURL } from "@~/common/lib/imgix"
import { Entry, Tag } from "@~/common/lib/microcms/media"
import { ChevronRightIcon } from "components/icons"
import NextLink from "next/link"
import { lightFormat } from "date-fns"
import { pagesPath } from "lib/$path"
import { getEntryPagePath } from "media/lib/urls"
import { SectionTitle } from "../../lp/SectionTitle"
import { BoxProps } from "@chakra-ui/react"

export type LearnMediaSectionProps = {
  recentEntries: Pick<
    Entry,
    "id" | "title" | "publishedAt" | "tag" | "eyecatch" | "createdAt"
  >[]
} & BoxProps

/** 記事から学ぶ */
export const LearnMediaSection = ({
  recentEntries,
  ...props
}: LearnMediaSectionProps) => {
  const { imgW, imgH } = useResponsiveValue(
    { imgW: 140, imgH: 72 },
    { imgW: 175, imgH: 90 }
  )

  return (
    <chakra.div
      id="learn-media"
      as="section"
      maxW={{ base: "full", md: "685px" }}
      mx="auto"
      w="full"
      {...props}
    >
      <SectionTitle subTitle="すべて無料！" title="記事から学ぶ" />
      <Divider mt={4} borderBottomColor="line.gray" />
      <VStack
        mt={3}
        spacing={3}
        divider={<Divider borderBottomColor="line.gray" />}
        data-testmask="static"
      >
        {recentEntries.map((entry) => (
          <Grid
            key={entry.id}
            w="full"
            templateRows="1fr auto"
            gridColumnGap={{ base: 2, md: 3 }}
            templateColumns={{
              base: `${imgW}px auto`,
              md: `${imgW}px auto`,
            }}
          >
            <GridItem rowSpan={2}>
              <Image
                alt=""
                objectFit="cover"
                w={`${imgW}px`}
                h={`${imgH}px`}
                src={imgixURL(
                  entry.eyecatch?.url,
                  `w=${imgW * 2}&h=${imgH * 2}&fit=crop&crop=faces`
                )}
                ignoreFallback={!!entry.eyecatch}
                borderRadius={{ base: "3px", md: 2 }}
              />
            </GridItem>
            <GridItem>
              <NextLink
                href={getEntryPagePath(entry).$url()}
                passHref
                legacyBehavior
              >
                <chakra.h3
                  as="a"
                  w="full"
                  textStyle="f16Bold"
                  mb="0.5"
                  lineHeight="20px"
                  noOfLines={3}
                >
                  {entry.title}
                </chakra.h3>
              </NextLink>
            </GridItem>
            <GridItem>
              <HStack
                textStyle="note"
                color="text.gray"
                spacing="2"
                fontWeight="bold"
              >
                <chakra.span>
                  {lightFormat(
                    new Date(entry.publishedAt ?? entry.createdAt),
                    "yyyy/MM/dd"
                  )}
                </chakra.span>
                {entry.tag.map((t: Tag) => (
                  <chakra.span key={t.id}>#{t.name}</chakra.span>
                ))}
              </HStack>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Divider mt={3} borderBottomColor="line.gray" />
      <chakra.footer mt="3" textAlign="center">
        <NextLink href={pagesPath.media.$url()} passHref legacyBehavior>
          <Button
            as="a"
            variant="link"
            // TODO: add variant
            color="text.main"
            textStyle="f14Bold"
            fontWeight="bold"
            fontSize="14px"
            rightIcon={<ChevronRightIcon />}
          >
            記事一覧を見る
          </Button>
        </NextLink>
      </chakra.footer>
    </chakra.div>
  )
}
