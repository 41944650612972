import {
  staticPath_assets_top_mv_1_png,
  staticPath_assets_top_mv_2_png,
  staticPath_assets_top_mv_3_png,
  staticPath_assets_top_mv_4_png,
} from "lib/$asset-path"
import { chakra, Img, ImgProps, Link, Stack, VStack } from "@chakra-ui/react"
import { ChakraSwiper, SwiperSlide } from "@~/common/components"
import { MainVisualAppeal } from "components/lp/widgets/MainVisualAppeal"
import { Visible } from "components/system"
import { pagesPath } from "lib/$path"

import Head from "next/head"
import NextLink from "next/link"
import React from "react"
import { Autoplay } from "swiper"
import "swiper/css"
import { CTAButton } from "../common/CTAButton"

export type MainVisualProps = {}

export const MainVisual = React.forwardRef<HTMLElement>(
  ({ ...props }: MainVisualProps, ref) => {
    return (
      <>
        <Head>
          {/* スライド画像の先頭から2枚だけpreloadしておく */}
          <link rel="preload" as="image" href={IMAGES[0].src} />
          <link rel="preload" as="image" href={IMAGES[1].src} />
        </Head>
        <chakra.section ref={ref}>
          <Stack
            spacing={{ base: 2, md: 6 }}
            direction={{ base: "column", md: "row" }}
            justify="center"
            align="center"
            bgColor="moneiro-color-main-orange"
            pt={{ base: 5, md: 12 }}
            pb={{ base: 7, md: 12 }}
            pos="relative"
          >
            <Stack spacing={{ base: 0, md: 4 }}>
              <Stack
                as="h1"
                id="main-visual"
                spacing={2}
                color="moneiro-color-white"
              >
                <chakra.div
                  fontWeight="bold"
                  fontSize={{ base: "45px", md: "51px" }}
                  lineHeight={{ base: "48px", md: "61px" }}
                  letterSpacing="0.1em"
                >
                  知識ゼロでも
                  <br />
                  ただしい
                  <br />
                  資産運用を
                </chakra.div>
                <Stack
                  spacing={0}
                  direction={{ base: "column", md: "row" }}
                  justify="center"
                  align="center"
                >
                  <chakra.div textStyle="16-bold">
                    お金の診断・相談サービス
                  </chakra.div>
                  <chakra.div textStyle="24-bold">「マネイロ」</chakra.div>
                </Stack>
              </Stack>
              <Visible mdOnly>
                <CTAButton />
              </Visible>
            </Stack>
            <ChakraSwiper
              spaceBetween={15}
              loop
              modules={[Autoplay]}
              autoplay={{ delay: 3000 }}
              w={{ base: "278px", md: "339px" }}
              marginLeft={0}
              marginRight={0}
            >
              {IMAGES.map((image, key) => {
                return (
                  <SwiperSlide key={key}>
                    <Img
                      w={{ base: "278px", md: "339px" }}
                      h={{ base: "265px", md: "324" }}
                      alt=""
                      {...image}
                    />
                  </SwiperSlide>
                )
              })}
            </ChakraSwiper>
            <Visible baseOnly pos="absolute" bottom="-26px">
              <CTAButton />
            </Visible>
          </Stack>
          <SbiBanner />
          <MainVisualAppeal py={4} />
        </chakra.section>
      </>
    )
  }
)
MainVisual.displayName = "MainVisual"

const IMAGES: Pick<ImgProps, "src" | "alt">[] = [
  {
    src: staticPath_assets_top_mv_1_png,
    alt: "NISAとiDeCo、どっちがいい",
  },
  {
    src: staticPath_assets_top_mv_2_png,
    alt: "お金の基本から教えてほしい",
  },
  {
    src: staticPath_assets_top_mv_3_png,
    alt: "預貯金だけじゃもったいない気がする",
  },
  {
    src: staticPath_assets_top_mv_4_png,
    alt: "このままで、将来大丈夫かな？",
  },
]

const SbiBanner = () => {
  return (
    <VStack
      as="h2"
      spacing={1}
      textStyle="13-bold"
      color="moneiro-color-main-orange"
      py={4}
      // スマホサイズではCTAボタンが半分はみ出しているため、その分のマージンをとる
      mt={{ base: 5, md: 0 }}
    >
      <chakra.div>【お知らせ】</chakra.div>
      <NextLink href={pagesPath.consulting.sbi.$url()} passHref legacyBehavior>
        <Link isExternal textDecoration="underline" textUnderlineOffset="5px">
          SBI証券と提携し、ご相談の幅が広がりました
        </Link>
      </NextLink>
    </VStack>
  )
}
