import { staticPath_assets_illust_raise_zero_yen_svg } from "lib/$asset-path"
import { Center, chakra, Divider, Flex, VStack } from "@chakra-ui/react"
import { NextImage } from "components/system"
import { __ } from "data/texts/texts"

import React from "react"
import { SectionTitle } from "../SectionTitle"

export type ComplianceSectionProps = {}

/** マネイロ 3つのお約束 */
export const ComplianceSection = ({ ...props }: ComplianceSectionProps) => {
  return (
    <Flex
      id="compliance"
      w="full"
      justifyContent="center"
      mt={{ base: "8", md: "12" }}
    >
      <VStack spacing="4" w="full" maxW="825px" px="3">
        <SectionTitle
          subTitle={__("compliance.st")}
          title={__("compliance.t")}
        />
        <VStack
          w="full"
          spacing="3"
          pt={{ base: "4", md: "6" }}
          px={{ base: "3", md: "105px" }}
          borderRadius="lg"
          background="bg.sub"
          alignItems="flex-start"
        >
          <Item num={1} message={__("compliance.l1")} />
          <Divider variant="dashed" borderBottomColor="text.gray3" />
          <Item num={2} message={__("compliance.l2")} />
          <Divider variant="dashed" borderBottomColor="text.gray3" />
          <Item num={3} message={__("compliance.l3")} />
          <Center w="full">
            <NextImage
              width="140px"
              height="121px"
              src={staticPath_assets_illust_raise_zero_yen_svg}
              alt=""
            />
          </Center>
        </VStack>
      </VStack>
    </Flex>
  )
}

type ItemProps = {
  num: number
  message: string
}
const Item = (props: ItemProps) => (
  <Flex alignItems="baseline">
    <Center
      bgColor="ui.main"
      w="6"
      h="6"
      flexShrink={0}
      borderRadius="4px"
      color="white"
      fontSize="md"
    >
      {props.num}
    </Center>
    <chakra.p
      ml="3"
      fontSize={{ base: "14px", md: "16px" }}
      letterSpacing="normal"
    >
      {props.message}
    </chakra.p>
  </Flex>
)
