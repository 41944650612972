import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Button, chakra, HStack, VStack } from "@chakra-ui/react"
import { Ribbon } from "components/lp/widgets/Ribbon"
import NextLink from "next/link"
import { pagesPath } from "lib/$path"
import React from "react"

export type CTAButtonProps = {}

export const CTAButton = ({ ...props }: CTAButtonProps) => {
  return (
    <NextLink href={pagesPath.simulation.$3min.$url()} passHref legacyBehavior>
      <Button
        as="a"
        w="308px"
        h="53px"
        colorScheme="secondary"
        justifyContent="space-between"
        alignItems="center"
        rightIcon={<ChevronRightIcon boxSize="32px" />}
        leftIcon={<Box boxSize="32px" />}
        pos="relative"
      >
        <Box pos="absolute" top="-10px">
          <Ribbon pt={1}>
            <VStack spacing="1px" fontWeight="bold">
              <chakra.div fontSize="10px">無料</chakra.div>
              <HStack spacing="1px" align="flex-end">
                <chakra.div fontSize="12px">¥</chakra.div>
                <chakra.div textStyle="24-bold" lineHeight="24px">
                  0
                </chakra.div>
              </HStack>
            </VStack>
          </Ribbon>
        </Box>
        <VStack spacing={0}>
          <chakra.div textStyle="12-bold">まずはここから</chakra.div>
          <chakra.div textStyle="16-bold">3分投資診断</chakra.div>
        </VStack>
      </Button>
    </NextLink>
  )
}
