import { Center } from "@chakra-ui/react"
import { useIsDisplayed } from "@~/common/components/useIsDisplayed"
import { usePtengine } from "@~/common/components/usePtengine"
import { useAffiliateModal } from "components/affiliate-modal/useAffiliateModal"
import { CustomerReviewSection } from "components/lp/consulting/CustomerReview"
import { Layer } from "components/lp/Layer"
import { NewsSection } from "components/lp/NewsSection"
import { ComplianceSection } from "components/lp/widgets/ComplianceSection"
import { Corporate } from "components/lp/widgets/Corporate"
import { LearnMediaSection } from "components/lp/widgets/LearnMediaSection"
import { LinkToFaqPage } from "components/lp/widgets/LinkToFaqPage"
import { MediaCoverageList } from "components/lp/widgets/MediaCoverageList"
import { PageProps } from "pages"
import LazyHydrate from "react-lazy-hydration"
import { ConsultingCase } from "./ConsultingCase"
import { ConsultingCaseB } from "./ConsultingCase/ConsultingCaseB"
import { FollowUpCTA } from "./FollowUpCTA"
import { MainVisual } from "./MainVisual"
import { Movie } from "./Movie"
import { WhatYouCanDo } from "./WhatYouCanDo"

export type TopProps = PageProps

export const Top = (props: TopProps) => {
  const { targetRef: mainVisualRef, isDisplayed: isDisplayedMainVisual } =
    useIsDisplayed()
  const { targetRef: movieRef, isDisplayed: isDisplayedMovie } =
    useIsDisplayed()
  const { targetRef: whatYouCanDoRef, isDisplayed: isDisplayedWhatYouCanDo } =
    useIsDisplayed()
  const isVisibleFollowUpCTA =
    !isDisplayedMainVisual && !isDisplayedMovie && !isDisplayedWhatYouCanDo
  const renderAffiliateModal = useAffiliateModal()

  const {
    abtest: { isTestConsultingCase },
  } = usePtengine()

  return (
    <>
      <MainVisual ref={mainVisualRef} />
      {renderAffiliateModal()}
      <NewsSection
        news={props.siteInfo.news}
        colorScheme="gray"
        w="full"
        maxW="container.secondContent"
        mx="auto"
      />
      <Movie ref={movieRef} />
      <WhatYouCanDo ref={whatYouCanDoRef} />
      <LazyHydrate whenIdle>
        <>
          <FollowUpCTA isVisible={isVisibleFollowUpCTA} />
          {/* NOTE: A/Bテスト実施 MONEIRO-1195 */}
          {isTestConsultingCase ? <ConsultingCaseB /> : <ConsultingCase />}
          <CustomerReviewSection category="all" limit={3} />
          <ComplianceSection />
          <LearnMediaSection mt={12} px={2} recentEntries={props.media.data} />
          <Center px={{ base: 3, md: 0 }} mt={{ base: 4, md: 12 }}>
            <LinkToFaqPage />
          </Center>
          <MediaCoverageList py={0} px={{ base: 1, md: 3 }} mt={8} />
          <Layer
            maxW={"container.content"}
            mt={20}
            mb={12}
            titleLabel={"運営会社"}
            variant="condenced"
            isFullWidth
          >
            <Corporate mt={8} />
          </Layer>
        </>
      </LazyHydrate>
    </>
  )
}
