import { chakra, ChakraProps, TypographyProps } from "@chakra-ui/react"

type Props = {
  title: React.ReactNode
  subTitle?: React.ReactNode
  subTitleStyle?: TypographyProps & ChakraProps
} & TypographyProps &
  ChakraProps

export const SectionTitle = (props: Props) => {
  const { title, subTitle, subTitleStyle, ...style } = props
  return (
    <chakra.h2
      textAlign="center"
      textStyle={{ base: "f20Bold", md: "f24Bold" }}
      lineHeight={{ base: "28px", md: "32px" }}
      fontWeight="bold"
      letterSpacing="0.065em"
      {...style}
    >
      {subTitle && (
        <chakra.div
          textStyle={{ base: "f12Bold", md: "f13Bold" }}
          color="text.main"
          {...subTitleStyle}
        >
          {subTitle}
        </chakra.div>
      )}
      {title}
    </chakra.h2>
  )
}
