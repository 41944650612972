// @~/common/lib/media/urlsのpathPida版
import { Entry } from "@~/common/lib/microcms/media"
import { pagesPath } from "lib/$path"

export const getEntryPagePath = (entry: Pick<Entry, "id">) =>
  pagesPath.media.article._id(entry.id)

export const getIndexPagePath = () => pagesPath

export const getCategoryIndexPagePath = (tagID: string) =>
  pagesPath.media.category._id(tagID)
