import { NextImageProps } from "components/system"
import {
  staticPath_assets_consulting_cases_edu_circlegraph_after_base_png,
  staticPath_assets_consulting_cases_edu_circlegraph_after_md_png,
  staticPath_assets_consulting_cases_edu_circlegraph_before_base_png,
  staticPath_assets_consulting_cases_edu_circlegraph_before_md_png,
  staticPath_assets_consulting_cases_future_circlegraph_after_png,
  staticPath_assets_consulting_cases_future_circlegraph_before_png,
  staticPath_assets_consulting_cases_ideco_circlegraph_after_base_png,
  staticPath_assets_consulting_cases_ideco_circlegraph_after_md_png,
  staticPath_assets_consulting_cases_ideco_circlegraph_before_png,
  staticPath_assets_consulting_cases_insurance_bargraph_after_png,
  staticPath_assets_consulting_cases_insurance_bargraph_before_png,
  staticPath_assets_consulting_cases_mutual_funds_circlegraph_after_base_png,
  staticPath_assets_consulting_cases_mutual_funds_circlegraph_after_md_png,
  staticPath_assets_consulting_cases_mutual_funds_circlegraph_before_base_png,
  staticPath_assets_consulting_cases_mutual_funds_circlegraph_before_md_png,
  staticPath_assets_consulting_cases_nisa_circlegraph_after_png,
  staticPath_assets_consulting_cases_nisa_circlegraph_before_png,
  staticPath_assets_illusts_user_man_30_main_svg,
  staticPath_assets_illusts_user_man_30_sub_svg,
  staticPath_assets_illusts_user_man_50_main_svg,
  staticPath_assets_illusts_user_woman_30_sub_svg,
  staticPath_assets_illusts_user_woman_40_main_svg,
} from "lib/$asset-path"

import React from "react"

const CONSULTING_CASE_KINDS = [
  "future",
  "edu",
  "nisa",
  "ideco",
  "mutual-funds",
  "insurance",
] as const

type ConsultingCaseKind = typeof CONSULTING_CASE_KINDS[number]

export type ConsultingCaseData = {
  /** 相談事例の種類。それぞれのURLに対応しています */
  kind: ConsultingCaseKind
  kindI18n: string
  customerProfile: {
    meta?: React.ReactNode
    // 年収
    yearlyIncome?: number
    // 年収 or 世帯年収
    income?: React.ReactNode // e.g "年収420万円", "世帯年収650万円"
    // 年齢
    age?: number // TODO: テスト用と現行のデータが混在してるので一旦オプショナルにしてるがテスト用に変える場合は消す
    // 属性
    type?: string // e.g "独身女性" // TODO: テスト用と現行のデータが混在してるので一旦オプショナルにしてるがテスト用に変える場合は消す
    // 表示名
    displayName?: string // e.g "Sさん", "Kさん夫婦" // TODO: テスト用と現行のデータが混在してるので一旦オプショナルにしてるがテスト用に変える場合は消す
    // メッセージ
    message?: React.ReactNode // e.g "“将来の資産3400万円を目指して、資産運用を始められました！”" // TODO: テスト用と現行のデータが混在してるので一旦オプショナルにしてるがテスト用に変える場合は消す
    // プロフィール画像
    img: {
      base: NextImageProps
      md: NextImageProps
    }
  }
  before: {
    amount?: number
    method?: "funded" | "bulk"
    graph: {
      base: NextImageProps
      md: NextImageProps
    }
    comments: React.ReactNode[]
  }
  after: {
    graph: {
      base: NextImageProps
      md: NextImageProps
    }
    comments: React.ReactNode[]
  }
}

export const FUTURE_CASE_DATA: ConsultingCaseData = {
  kind: "future",
  kindI18n: "将来資金",
  customerProfile: {
    meta: "会社員8年目 独身女性",
    yearlyIncome: 420,
    img: {
      base: {
        src: staticPath_assets_illusts_user_woman_30_sub_svg,
        width: 74,
        height: 88,
      },
      md: {
        src: staticPath_assets_illusts_user_woman_30_sub_svg,
        width: 66,
        height: 78,
      },
    },
  },
  before: {
    amount: 2,
    method: "funded",
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_future_circlegraph_before_png,
        width: 158,
        height: 158,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
      md: {
        src: staticPath_assets_consulting_cases_future_circlegraph_before_png,
        width: 168,
        height: 168,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
    },
    comments: ["将来が不安なのでひとまず預金…", "NISAとiDeCoで迷う…"],
  },
  after: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_future_circlegraph_after_png,
        width: 175,
        height: 175,
        alt: "ご相談後 積極的に殖やす資産100%",
      },
      md: {
        src: staticPath_assets_consulting_cases_future_circlegraph_after_png,
        width: 195,
        height: 195,
        alt: "ご相談後 積極的に殖やす資産100%",
      },
    },
    comments: [
      "運用方針が明確になって安心！",
      <>
        今使えるお金が
        <br />
        予想以上に多くてびっくり！
      </>,
      "将来を考えるのが楽しみになった！",
    ],
  },
}

export const EDU_CASE_DATA: ConsultingCaseData = {
  kind: "edu",
  kindI18n: "教育資金",
  customerProfile: {
    meta: "会社員15年目 既婚子育て中男性",
    yearlyIncome: 650,
    img: {
      base: {
        src: staticPath_assets_illusts_user_man_30_sub_svg,
        width: 78,
        height: 88,
      },
      md: {
        src: staticPath_assets_illusts_user_man_30_sub_svg,
        width: 69,
        height: 78,
      },
    },
  },
  before: {
    amount: 5,
    method: "funded",
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_edu_circlegraph_before_base_png,
        width: 298,
        height: 163,
        alt: "ご相談前 守る資産（普通預金）80% 守りながら殖やす資産20%",
      },
      md: {
        src: staticPath_assets_consulting_cases_edu_circlegraph_before_md_png,
        width: 226,
        height: 167,
        alt: "ご相談前 守る資産（普通預金）80% 守りながら殖やす資産20%",
      },
    },
    comments: [
      "今は預金と学資保険だけ…",
      <>
        子供の教育資金と自分の将来資金が
        <br />
        なんとなく心配…
      </>,
    ],
  },
  after: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_edu_circlegraph_after_base_png,
        width: 298,
        height: 178,
        alt: "ご相談後 守る資産20% 守りながら殖やす資産20% 積極的に殖やす資産60%",
      },
      md: {
        src: staticPath_assets_consulting_cases_edu_circlegraph_after_md_png,
        width: 333,
        height: 198,
        alt: "ご相談後 守る資産20% 守りながら殖やす資産20% 積極的に殖やす資産60%",
      },
    },
    comments: [
      <>
        教育資金は学資保険と預金で対応で
        <br />
        きると分かった！
      </>,
      <>
        預金だけでは将来資金が足りない
        <br />
        と分かり、攻めのプランを検討！
      </>,
      <>
        毎月3万円を長期積立投資で運用す
        <br />
        ることに！
      </>,
    ],
  },
}

export const NISA_CASE_DATA: ConsultingCaseData = {
  kind: "nisa",
  kindI18n: "NISA",
  customerProfile: {
    meta: "会社員10年目 独身女性",
    yearlyIncome: 380,
    img: {
      base: {
        src: staticPath_assets_illusts_user_woman_40_main_svg,
        width: 70,
        height: 88,
      },
      md: {
        src: staticPath_assets_illusts_user_woman_40_main_svg,
        width: 66,
        height: 78,
      },
    },
  },
  before: {
    amount: 3,
    method: "funded",
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_before_png,
        width: 158,
        height: 158,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
      md: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_before_png,
        width: 168,
        height: 168,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
    },
    comments: ["同僚がNISAを始めた", "NISAが気になるが難しそう…"],
  },
  after: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_after_png,
        width: 176,
        height: 176,
        alt: "ご相談後 積極的に殖やす資産100%",
      },
      md: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_after_png,
        width: 195,
        height: 195,
        alt: "ご相談後 積極的に殖やす資産100%",
      },
    },
    comments: [
      <>
        プロと一緒にNISAを
        <br />
        月2万円でスタート！
      </>,
      "余った予算は投資信託に！",
      <>
        将来の必要資金から逆算したプラン
        <br />
        で安心できた！
      </>,
    ],
  },
}

export const TSUMI_NISA_CASE_DATA: ConsultingCaseData = {
  kind: "nisa",
  kindI18n: "つみたてNISA",
  customerProfile: {
    meta: "会社員10年目 独身女性",
    yearlyIncome: 380,
    img: {
      base: {
        src: staticPath_assets_illusts_user_woman_40_main_svg,
        width: 70,
        height: 88,
      },
      md: {
        src: staticPath_assets_illusts_user_woman_40_main_svg,
        width: 66,
        height: 78,
      },
    },
  },
  before: {
    amount: 3,
    method: "funded",
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_before_png,
        width: 158,
        height: 158,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
      md: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_before_png,
        width: 168,
        height: 168,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
    },
    comments: [
      "同僚がつみたてNISAを始めた",
      "つみたてNISAが気になるが難しそう…",
    ],
  },
  after: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_after_png,
        width: 176,
        height: 176,
        alt: "ご相談後 積極的に殖やす資産100%",
      },
      md: {
        src: staticPath_assets_consulting_cases_nisa_circlegraph_after_png,
        width: 195,
        height: 195,
        alt: "ご相談後 積極的に殖やす資産100%",
      },
    },
    comments: [
      <>
        プロと一緒につみたてNISAを
        <br />
        月2万円でスタート！
      </>,
      "余った予算は投資信託に！",
      <>
        将来の必要資金から逆算したプラン
        <br />
        で安心できた！
      </>,
    ],
  },
}

export const IDECO_CASE_DATA: ConsultingCaseData = {
  kind: "ideco",
  kindI18n: "iDeCo",
  customerProfile: {
    meta: "会社員10年目 既婚男性",
    yearlyIncome: 680,
    img: {
      base: {
        src: staticPath_assets_illusts_user_man_30_main_svg,
        width: 74,
        height: 86,
      },
      md: {
        src: staticPath_assets_illusts_user_man_30_main_svg,
        width: 66,
        height: 78,
      },
    },
  },
  before: {
    amount: 7,
    method: "funded",
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_ideco_circlegraph_before_png,
        width: 158,
        height: 158,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
      md: {
        src: staticPath_assets_consulting_cases_ideco_circlegraph_before_png,
        width: 168,
        height: 168,
        alt: "ご相談前 守る資産（普通預金）100%",
      },
    },
    comments: ["家計のことは妻にお任せ", "妻がiDeCoをやりたいと言い始め相談に"],
  },
  after: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_ideco_circlegraph_after_base_png,
        width: 298,
        height: 178,
        alt: "ご相談後 守る資産40% 積極的に殖やす資産60%",
      },
      md: {
        src: staticPath_assets_consulting_cases_ideco_circlegraph_after_md_png,
        width: 333,
        height: 195,
        alt: "ご相談後 守る資産40% 積極的に殖やす資産60%",
      },
    },
    comments: [
      <>
        妻がiDeCoをやっても効果が小さく、
        <br />
        自分がやるべきだと分かった！
      </>,
      "毎月2.3万円をiDeCoに、1.9万円を投資信託に投資して残りは貯金！",
      "漠然とした老後への不安がなくなった！",
    ],
  },
}

export const MUTUAL_FUNDS_CASE_DATA: ConsultingCaseData = {
  kind: "mutual-funds",
  kindI18n: "投資信託",
  customerProfile: {
    meta: "会社役員20年目 既婚男性",
    yearlyIncome: 2000,
    img: {
      base: {
        src: staticPath_assets_illusts_user_man_50_main_svg,
        width: 73,
        height: 86,
      },
      md: {
        src: staticPath_assets_illusts_user_man_50_main_svg,
        width: 66,
        height: 78,
      },
    },
  },
  before: {
    amount: 3000,
    method: "bulk",
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_mutual_funds_circlegraph_before_base_png,
        width: 298,
        height: 162,
        alt: "ご相談前 守りながら殖やす資産50% 積極的に殖やす資産50%",
      },
      md: {
        src: staticPath_assets_consulting_cases_mutual_funds_circlegraph_before_md_png,
        width: 226,
        height: 167,
        alt: "ご相談前 守りながら殖やす資産50% 積極的に殖やす資産50%",
      },
    },
    comments: [
      "余裕資金のよりよい運用先を探している",
      <>
        証券会社で勧められた投資信託の
        <br />
        成績が悪く見直しで悩んでいる
      </>,
    ],
  },
  after: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_mutual_funds_circlegraph_after_base_png,
        width: 298,
        height: 178,
        alt: "ご相談後 守りながら殖やす資産70% 積極的に殖やす資産30%",
      },
      md: {
        src: staticPath_assets_consulting_cases_mutual_funds_circlegraph_after_md_png,
        width: 333,
        height: 198,
        alt: "ご相談後 守りながら殖やす資産70% 積極的に殖やす資産30%",
      },
    },
    comments: [
      "持っていた投資信託は売却し、成果が期待できる投資信託に入れ替え！",
      "目標額から逆算すると、これまではリスクを取り過ぎていたことが判明。より安全に運用する方針に！",
    ],
  },
}

export const INSURANCE_CASE_DATA: ConsultingCaseData = {
  kind: "insurance",
  kindI18n: "保険の見直し",
  customerProfile: {
    meta: "会社員12年目 独身女性",
    yearlyIncome: 320,
    img: {
      base: {
        src: staticPath_assets_illusts_user_woman_30_sub_svg,
        width: 74,
        height: 88,
      },
      md: {
        src: staticPath_assets_illusts_user_woman_30_sub_svg,
        width: 66,
        height: 78,
      },
    },
  },
  before: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_insurance_bargraph_before_png,
        width: 292,
        height: 183,
        alt: "医療保険/10年前に加入 入院日額1万円 月額保険料1万円",
      },
      md: {
        src: staticPath_assets_consulting_cases_insurance_bargraph_before_png,
        width: 292,
        height: 183,
        alt: "医療保険/10年前に加入 入院日額1万円 月額保険料1万円",
      },
    },
    comments: [
      "新卒入社した会社に出入りしていた生保の方のおススメで加入",
      "医療保険の負担を抑えて、資産運用にまわしたいと思うようになった",
    ],
  },
  after: {
    graph: {
      base: {
        src: staticPath_assets_consulting_cases_insurance_bargraph_after_png,
        width: 292,
        height: 183,
        alt: "最新の医療保険に見直し 入院日額1万円 月額保険料5千円 同じ条件で半額に",
      },
      md: {
        src: staticPath_assets_consulting_cases_insurance_bargraph_after_png,
        width: 292,
        height: 183,
        alt: "最新の医療保険に見直し 入院日額1万円 月額保険料5千円 同じ条件で半額に",
      },
    },
    comments: [
      "保障内容はそのまま+αで費用が大幅減！",
      "医療保険の保険料を下げた分は積立投資に！",
    ],
  },
}

export const CONSULTING_CASE_DATA: ConsultingCaseData[] = [
  FUTURE_CASE_DATA,
  EDU_CASE_DATA,
  NISA_CASE_DATA,
  IDECO_CASE_DATA,
  MUTUAL_FUNDS_CASE_DATA,
  INSURANCE_CASE_DATA,
]
