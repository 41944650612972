import { Box, BoxProps, HStack, StackProps } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

export type OwataLabelProps = {
  labelProps?: BoxProps
  handProps?: BoxProps
} & StackProps

/** ＼(^o^)／ｵﾜﾀ */
export const OwataLabel = ({
  labelProps,
  handProps,
  children,
  ...props
}: PropsWithChildren<OwataLabelProps>) => (
  <HStack spacing={0} {...props}>
    <OwataHand direction="left" {...handProps} />
    <Box px={1} {...labelProps}>
      {children}
    </Box>
    <OwataHand direction="right" {...handProps} />
  </HStack>
)

const OwataHand = ({
  direction,
  ...props
}: {
  direction: "left" | "right"
} & BoxProps) => (
  <Box
    w="24px"
    h="2px"
    bgColor="moneiro-color-text-black"
    transform={direction === "left" ? "rotate(-120deg)" : "rotate(120deg)"}
    role="presentation"
    {...props}
  />
)
