import {
  staticPath_assets_illust_user_female_6_svg,
  staticPath_assets_lp_top_before_plan_graph_1_png,
  staticPath_assets_lp_top_after_plan_graph_1_png,
  staticPath_assets_illust_user_male_2_svg,
  staticPath_assets_lp_top_before_plan_graph_2_png,
  staticPath_assets_lp_top_after_plan_graph_2_png,
  staticPath_assets_illust_user_female_7_svg,
  staticPath_assets_lp_top_before_plan_graph_3_png,
  staticPath_assets_lp_top_after_plan_graph_3_png,
} from "lib/$asset-path"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import {
  Accordion,
  AccordionButton as ChakraAccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  HStack,
  Stack,
  useAccordionItemState,
  VStack,
} from "@chakra-ui/react"
import {
  ConsultingCaseBody,
  ConsultingCaseCustomerProfile,
  ConsultingCaseData,
} from "components/lp/consulting/ConsultingCase"
import { Ribbon } from "components/lp/widgets/Ribbon"

export type ConsultingCaseProps = {}

export const ConsultingCase = ({ ...props }: ConsultingCaseProps) => {
  return (
    <chakra.section>
      <VStack
        spacing={3}
        bgColor="moneiro-color-main-orange"
        px={{ base: 3, md: 0 }}
        py={{ base: 6, md: 10 }}
      >
        <chakra.h2
          id="consulting-case"
          fontWeight="bold"
          fontSize={{ base: "20px", md: "24px" }}
          textAlign="center"
          color="moneiro-color-white"
        >
          マネイロに相談した方の
          <br />
          資産運用事例
        </chakra.h2>
        <ConsultingCaseAccordion />
      </VStack>
    </chakra.section>
  )
}

/** TODO: components/lp/consulting/ConsultingCaseのパーツを拝借しているがもう少し抽象化したパーツに切り分けてもよさそう */
const ConsultingCaseAccordion = () => {
  return (
    <Accordion
      defaultIndex={[0]}
      allowMultiple
      w="full"
      maxW="container.content"
    >
      <Stack spacing={4}>
        {TOP_CASE_DATA.map((data, key) => {
          const { customerProfile, before, after } = data

          return (
            <AccordionItem
              layerStyle="base"
              pt={{ base: 4, md: 8 }}
              border="none"
              pos="relative"
              key={key}
            >
              <Box pos="absolute" top="-10px">
                <Ribbon
                  ribonColor="moneiro-color-darkblue"
                  ribonWidth={45}
                  ribonHeight={{ base: 50, decoration: 15 }}
                >
                  <VStack
                    spacing="1px"
                    justify="center"
                    h="full"
                    color="moneiro-color-white"
                  >
                    <chakra.div fontSize="11px">CASE</chakra.div>
                    <chakra.div textStyle="18-reg" lineHeight="18px">
                      {key + 1}
                    </chakra.div>
                  </VStack>
                </Ribbon>
              </Box>
              <ConsultingCaseCustomerProfile
                customerProfile={customerProfile}
              />
              <AccordionPanel
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={0}
                py={6}
              >
                <ConsultingCaseBody before={before} after={after} />
              </AccordionPanel>
              <AccordionButton />
            </AccordionItem>
          )
        })}
      </Stack>
    </Accordion>
  )
}

const AccordionButton = () => {
  const { isOpen } = useAccordionItemState()

  return (
    <ChakraAccordionButton
      bgColor={
        isOpen
          ? "moneiro-color-fill-gray-sub"
          : "moneiro-color-fill-pale-yellow"
      }
      px={0}
      py={1}
      borderBottomRadius="m"
    >
      <HStack
        spacing={1}
        justify="center"
        w="full"
        color={
          isOpen ? "moneiro-color-text-black" : "moneiro-color-main-orange"
        }
      >
        {isOpen ? (
          <>
            <chakra.div textStyle="13-bold">閉じる</chakra.div>
            <MinusIcon boxSize="13px" />
          </>
        ) : (
          <>
            <chakra.div textStyle="13-bold">詳しく見る</chakra.div>
            <AddIcon boxSize="13px" />
          </>
        )}
      </HStack>
    </ChakraAccordionButton>
  )
}

type TopCaseData = Pick<
  ConsultingCaseData,
  "customerProfile" | "before" | "after"
>

const TOP_CASE_DATA: TopCaseData[] = [
  {
    customerProfile: {
      meta: "会社員8年目 独身女性",
      yearlyIncome: 420,
      img: {
        base: {
          src: staticPath_assets_illust_user_female_6_svg,
          width: 66,
          height: 78,
        },
        md: {
          src: staticPath_assets_illust_user_female_6_svg,
          width: 66,
          height: 78,
        },
      },
    },
    before: {
      amount: 2,
      method: "funded",
      graph: {
        base: {
          src: staticPath_assets_lp_top_before_plan_graph_1_png,
          width: 158,
          height: 158,
        },
        md: {
          src: staticPath_assets_lp_top_before_plan_graph_1_png,
          width: 158,
          height: 158,
        },
      },
      comments: [
        "将来が不安なのでひとまず預金...",
        "つみたてNISAとiDeCoで迷う...",
      ],
    },
    after: {
      graph: {
        base: {
          src: staticPath_assets_lp_top_after_plan_graph_1_png,
          width: 195,
          height: 195,
        },
        md: {
          src: staticPath_assets_lp_top_after_plan_graph_1_png,
          width: 195,
          height: 195,
        },
      },
      comments: [
        "最低限必要な預金は確保できていると分かった！",
        "将来の目標額と資産運用プランが見つかった！",
        "毎月預金していた2万円はすべて攻めの運用に！",
      ],
    },
  },
  {
    customerProfile: {
      meta: "会社員15年目 既婚子持ち男性",
      yearlyIncome: 650,
      img: {
        base: {
          src: staticPath_assets_illust_user_male_2_svg,
          width: 69,
          height: 78,
        },
        md: {
          src: staticPath_assets_illust_user_male_2_svg,
          width: 69,
          height: 78,
        },
      },
    },
    before: {
      amount: 5,
      method: "funded",
      graph: {
        base: {
          src: staticPath_assets_lp_top_before_plan_graph_2_png,
          width: 298,
          height: 162,
        },
        md: {
          src: staticPath_assets_lp_top_before_plan_graph_2_png,
          width: 298,
          height: 162,
        },
      },
      comments: [
        "今は預金と学資保険だけ...",
        "子供の教育資金と自分の将来資金がなんとなく心配...",
      ],
    },
    after: {
      graph: {
        base: {
          src: staticPath_assets_lp_top_after_plan_graph_2_png,
          width: 333,
          height: 199,
        },
        md: {
          src: staticPath_assets_lp_top_after_plan_graph_2_png,
          width: 333,
          height: 199,
        },
      },
      comments: [
        "教育資金は学資保険と預金で対応できると分かった！",
        "預金だけでは将来資金が足りないと分かり、攻めのプランを検討！",
        "毎月3万円を長期積立投資で運用することに！",
      ],
    },
  },
  {
    customerProfile: {
      meta: "会社員22年目 独身女性",
      yearlyIncome: 920,
      img: {
        base: {
          src: staticPath_assets_illust_user_female_7_svg,
          width: 62,
          height: 78,
        },
        md: {
          src: staticPath_assets_illust_user_female_7_svg,
          width: 62,
          height: 78,
        },
      },
    },
    before: {
      amount: 10,
      method: "funded",
      graph: {
        base: {
          src: staticPath_assets_lp_top_before_plan_graph_3_png,
          width: 168,
          height: 168,
        },
        md: {
          src: staticPath_assets_lp_top_before_plan_graph_3_png,
          width: 168,
          height: 168,
        },
      },
      comments: [
        "元本割れが怖いのですべて預金...",
        "引退後も余裕のある生活をしたい...",
      ],
    },
    after: {
      graph: {
        base: {
          src: staticPath_assets_lp_top_after_plan_graph_3_png,
          width: 333,
          height: 195,
        },
        md: {
          src: staticPath_assets_lp_top_after_plan_graph_3_png,
          width: 333,
          height: 195,
        },
      },
      comments: [
        "預金だけでは将来資金が若干足りないことが分かった！",
        "守りながら増やす運用を取り入れれば、将来の目標額を達成する可能性が見えた！",
        "毎月5万円を長期積立投資で運用することに！",
      ],
    },
  },
]
