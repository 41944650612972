import { staticPath_assets_illusts_insta_ic_shitsumon_svg } from "lib/$asset-path"
import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Center,
  chakra,
  Img,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
} from "@chakra-ui/react"
import NextLink from "next/link"
import { pagesPath } from "lib/$path"

import React from "react"

export type LinkToFaqPageProps = LinkBoxProps

export const LinkToFaqPage = ({ ...props }: LinkToFaqPageProps) => {
  return (
    <LinkBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgColor="moneiro-color-main-orange"
      color="white"
      w="full"
      maxW="container.secondContent"
      pt={3}
      pb={{ base: 3, md: 0 }}
      pr={3}
      pl={{ base: 3, md: 9 }}
      borderRadius="10px"
      {...props}
    >
      <Img
        src={staticPath_assets_illusts_insta_ic_shitsumon_svg}
        w={{ base: "56px", md: "110px" }}
        h={{ base: "62px", md: "120px" }}
        htmlWidth="56px"
        htmlHeight="62px"
        alt=""
      />
      <NextLink href={pagesPath.faq.$url()} passHref legacyBehavior>
        <LinkOverlay>
          <chakra.div
            fontWeight="700"
            fontSize={{ base: "21px", md: "29px" }}
            letterSpacing="0.08em"
            textAlign="center"
          >
            <chakra.div
              fontSize={{ base: "11px", md: "16px" }}
              letterSpacing="0.05em"
            >
              サービスについての疑問を解消
            </chakra.div>
            よくあるご質問
          </chakra.div>
        </LinkOverlay>
      </NextLink>
      <Center justifyContent="flex-end" w={{ base: "56px", md: "110px" }}>
        <ChevronRightIcon boxSize="36px" />
      </Center>
    </LinkBox>
  )
}
