import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"

/** Ptengineに関連するロジックをまとめる
 * 基本的にABテスト用途で使われており、Ptengine側のみでは対応できないロジックなどを追加していく予定です。
 * 例: ABテストで設定されたパラメータを元にコンポーネントを出し分けたい場合、特定のパラメータを持っているか判定する、など
 */

type ExtendWindow = Window & {
  _moneiro_ptengine?: {
    testConsultingPopup?: TestConsultingPopupType
    testConsultingFlow?: {
      enable: boolean
      targetPaths?: [string]
    }
    testLateAuthConsultingFlow?: {
      enable: boolean
      targetPaths?: [string]
    }
    testRecommendMoneySeminar?: string
    testConsultingCase?: boolean
  }
}

export type TestConsultingPopupType = {
  // 30分以内の相談があった場合のメッセージ
  within30minutesMessage: string
  // 60分以内の相談があった場合のメッセージ
  within60minutesMessage: string
  // 24時間以内の相談があった場合のメッセージ
  within24hoursMessage: string
  // ポップアップが表示されるまでの時間(ミリ秒) / SPとPC共通
  popupOpenTime: number
  // ポップアップが消えるまでの時間(ミリ秒) / SPの場合
  popupSpCloseTime: number
  // ポップアップが消えるまでの時間(ミリ秒) / PCの場合
  popupPcCloseTime: number
}

export const usePtengine = () => {
  const router = useRouter()
  const [moneiroPtengine, setMoneiroPtengine] =
    useState<ExtendWindow["_moneiro_ptengine"]>(undefined)

  /**
   * ptengineからの値の設定は非同期なので、パラメーターを都度更新できるよう関数を用意する
   */
  const updateParameter = useCallback(() => {
    const _window = window as ExtendWindow
    if (_window._moneiro_ptengine) {
      setMoneiroPtengine(_window._moneiro_ptengine)
      return true
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      const result = updateParameter()
      if (result) {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [updateParameter])

  /**
   * コンサルティング回数ポップアップを表示するかどうか
   */
  const testConsultingPopup = (): TestConsultingPopupType | undefined => {
    return moneiroPtengine?.testConsultingPopup
  }

  /**
   * 相談予約フローのパターンBを使用するかどうか
   *  - 相談TOPの申し込みボタンの遷移先が /forms/consulting-b になる
   *  - 相談TOPのカレンダーの非表示にし、申込みの流れを表示する
   */
  const isTestConsultingFlow = useMemo(() => {
    const targetPaths = moneiroPtengine?.testConsultingFlow?.targetPaths ?? [
      "/consulting",
    ]
    return (
      targetPaths.includes(router.pathname) &&
      moneiroPtengine?.testConsultingFlow?.enable
    )
  }, [
    moneiroPtengine?.testConsultingFlow?.enable,
    moneiroPtengine?.testConsultingFlow?.targetPaths,
    router.pathname,
  ])

  /**
   * 相談予約フローのパターンCを使用するかどうか
   *  - 相談TOPの申し込みボタンの遷移先が /forms/consulting-c になる
   *  - 相談TOPのカレンダーの非表示にし、申込みの流れを表示する
   *  - フォーム入力後に認証を行う
   */
  const isTestLateAuthConsultingFlow = useMemo(() => {
    const targetPaths = moneiroPtengine?.testLateAuthConsultingFlow
      ?.targetPaths ?? ["/consulting"]
    return (
      targetPaths.includes(router.pathname) &&
      moneiroPtengine?.testLateAuthConsultingFlow?.enable
    )
  }, [
    moneiroPtengine?.testLateAuthConsultingFlow?.enable,
    moneiroPtengine?.testLateAuthConsultingFlow?.targetPaths,
    router.pathname,
  ])

  /**
   * 相談事例の表示をA/Bテストする
   */
  const isTestConsultingCase = useMemo(() => {
    return moneiroPtengine?.testConsultingCase
  }, [moneiroPtengine?.testConsultingCase])

  /**
   * マネーセミナーTOPでおすすめのセミナーを表示するABテスト
   */
  const testRecommendMoneySeminar = useMemo(() => {
    return moneiroPtengine?.testRecommendMoneySeminar
  }, [moneiroPtengine?.testRecommendMoneySeminar])

  return {
    abtest: {
      testConsultingPopup,
      isTestConsultingFlow,
      isTestLateAuthConsultingFlow,
      testRecommendMoneySeminar,
      isTestConsultingCase,
    },
  }
}
