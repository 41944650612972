import { Center, Fade } from "@chakra-ui/react"
import { NoSSR } from "components/system"
import React from "react"
import { CTAButton } from "../common/CTAButton"

export type FollowUpCTAProps = {
  isVisible: boolean
}

export const FollowUpCTA = ({ isVisible, ...props }: FollowUpCTAProps) => {
  return (
    <NoSSR>
      <Fade in={isVisible}>
        <Center
          bgColor="rgba(0,0,0,0.2)"
          pt={4}
          pb={2}
          pos="fixed"
          bottom={0}
          right={0}
          left={0}
          zIndex="sticky"
        >
          <CTAButton />
        </Center>
      </Fade>
    </NoSSR>
  )
}
