import {
  staticPath_assets_case_case_01_png,
  staticPath_assets_case_case_01_before_graph_png,
  staticPath_assets_case_case_01_after_graph_png,
  staticPath_assets_case_case_02_png,
  staticPath_assets_case_case_02_before_graph_png,
  staticPath_assets_case_case_02_after_graph_png,
  staticPath_assets_case_case_03_png,
  staticPath_assets_case_case_03_before_graph_png,
  staticPath_assets_case_case_03_after_graph_png,
} from "lib/$asset-path"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import {
  Accordion,
  AccordionButton as ChakraAccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  HStack,
  Stack,
  useAccordionItemState,
  VStack,
} from "@chakra-ui/react"
import imgIcSodan from "@~/assets/illusts/insta/ic-sodan.svg"
import {
  NextImage,
  NextImageInlineWrapper,
} from "@~/common/components/system/NextImage"
import {
  ConsultingCaseBody,
  ConsultingCaseCustomerProfile,
  ConsultingCaseData,
} from "components/lp/consulting/ConsultingCaseB"

import { Roboto } from "next/font/google"
const roboto = Roboto({
  weight: "400",
  subsets: ["latin"],
})

export type ConsultingCaseProps = {}

export const ConsultingCaseB = ({ ...props }: ConsultingCaseProps) => {
  return (
    <chakra.section>
      <VStack
        spacing={3}
        bgColor="moneiro-color-white"
        px={{ base: 3, md: 0 }}
        py={{ base: 6, md: 10 }}
      >
        {/* タイトル */}
        <chakra.div pos="relative" h={{ base: "144px", md: "auto" }}>
          <chakra.h2
            id="consulting-case"
            fontWeight="bold"
            fontSize={{ base: "20px", md: "24px" }}
            textAlign="center"
            color="moneiro-color-text-black"
          >
            マネイロに相談した方の
            <br />
            資産運用事例
          </chakra.h2>
          <chakra.div
            pos="absolute"
            bottom={{ base: "0px", md: "0px" }}
            right={{ base: "80px", md: "-130px" }}
          >
            <NextImageInlineWrapper
              mb={-1}
              width={{ base: 82, md: 112 }}
              height={{ base: 78, md: 105 }}
              pos="relative"
            >
              <NextImage
                src={imgIcSodan}
                layout="fill"
                objectFit="contain"
                alt=""
                unoptimized
              />
            </NextImageInlineWrapper>
          </chakra.div>
        </chakra.div>
        <ConsultingCaseAccordion />
      </VStack>
    </chakra.section>
  )
}

/** TODO: components/lp/consulting/ConsultingCaseのパーツを拝借しているがもう少し抽象化したパーツに切り分けてもよさそう */
const ConsultingCaseAccordion = () => {
  return (
    <Accordion
      defaultIndex={[0]}
      allowMultiple
      w="full"
      maxW="container.content"
    >
      <Stack spacing={4}>
        {TOP_CASE_DATA.map((data, key) => {
          const { customerProfile, before, after } = data

          return (
            <AccordionItem
              layerStyle="base"
              pt={{ base: 4, md: 2 }}
              borderWidth="2px"
              borderColor={"moneiro-color-main-orange"}
              borderRadius="m"
              pos="relative"
              key={key}
              _last={{ borderBottomWidth: "2px" }}
            >
              {/* ご相談事例 */}
              <Box
                pos="absolute"
                top="-1px"
                left={{ base: "calc(50% - 65px)", md: "-1px" }}
              >
                <HStack
                  spacing="1px"
                  justify="center"
                  h={{ base: "26px", md: "full" }}
                  color="moneiro-color-white"
                  bg="moneiro-color-main-orange"
                  borderTopLeftRadius={{ md: "m" }}
                  borderBottomRightRadius={"m"}
                  borderBottomLeftRadius={{ base: "m", md: 0 }}
                  w="130px"
                  lineHeight="22px"
                  py="4px"
                >
                  <chakra.div textStyle={"13-bold"}>ご相談事例</chakra.div>
                  <chakra.div textStyle={"16-bold"} fontFamily={"roboto"}>
                    0{key + 1}
                  </chakra.div>
                </HStack>
              </Box>
              {/* プロフィール */}
              <ConsultingCaseCustomerProfile
                customerProfile={customerProfile}
              />
              <AccordionPanel
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={5}
                py={5}
              >
                {/* ご相談前・ご相談後 */}
                <ConsultingCaseBody before={before} after={after} />
              </AccordionPanel>
              <AccordionButton />
            </AccordionItem>
          )
        })}
      </Stack>
      <chakra.div
        textAlign="left"
        w="full"
        maxW="container.content"
        display="flex"
        flexDir={"row"}
        gap="2px"
        mt={1}
      >
        <chakra.p textStyle={"12-reg"}>{`※`}</chakra.p>
        <chakra.p textStyle={"12-reg"}>
          {`マネイロのご相談事例をもとに、一部情報を加工してご紹介しております。`}
        </chakra.p>
      </chakra.div>
    </Accordion>
  )
}

const AccordionButton = () => {
  const { isOpen } = useAccordionItemState()

  return (
    <ChakraAccordionButton
      bgColor={
        isOpen ? "moneiro-color-fill-pale-yellow" : "moneiro-color-white"
      }
      px={0}
      py={1}
      borderBottomRadius="m"
    >
      <HStack
        spacing={1}
        justify="center"
        w="full"
        color={"moneiro-color-main-orange"}
      >
        {isOpen ? (
          <>
            <chakra.div textStyle="13-bold">閉じる</chakra.div>
            <MinusIcon boxSize="13px" />
          </>
        ) : (
          <>
            <chakra.div textStyle="13-bold">詳しく見る</chakra.div>
            <AddIcon boxSize="13px" />
          </>
        )}
      </HStack>
    </ChakraAccordionButton>
  )
}

type TopCaseData = Pick<
  ConsultingCaseData,
  "customerProfile" | "before" | "after"
>

const TOP_CASE_DATA: TopCaseData[] = [
  {
    customerProfile: {
      income: (
        <chakra.div>
          年収
          <chakra.span
            color="moneiro-color-main-orange"
            textStyle={"20-bold"}
            fontFamily="roboto"
            mx={"2px"}
          >
            420
          </chakra.span>
          万円
        </chakra.div>
      ),
      age: 46,
      displayName: "Sさん",
      type: "独身女性",
      message: (
        <chakra.div whiteSpace={"pre"}>
          {`“将来の資産3400万円を目指して、\n     資産運用を始められました！”`}
        </chakra.div>
      ),
      img: {
        base: {
          src: staticPath_assets_case_case_01_png,
          width: 120,
          height: 120,
        },
        md: {
          src: staticPath_assets_case_case_01_png,
          width: 120,
          height: 120,
        },
      },
    },
    before: {
      amount: 2,
      method: "funded",
      graph: {
        base: {
          src: staticPath_assets_case_case_01_before_graph_png,
          width: 315,
          height: 118,
        },
        md: {
          src: staticPath_assets_case_case_01_before_graph_png,
          width: 370,
          height: 140,
        },
      },
      comments: ["毎月残る10万円を積立投資したい", "貯めた600万円を投資したい"],
    },
    after: {
      graph: {
        base: {
          src: staticPath_assets_case_case_01_after_graph_png,
          width: 315,
          height: 178,
        },
        md: {
          src: staticPath_assets_case_case_01_after_graph_png,
          width: 370,
          height: 217,
        },
      },
      comments: [
        <chakra.span key={1} textStyle={{ base: "16-bold" }}>
          毎月の預金は、
          <chakra.span color="moneiro-color-main-orange">NISA</chakra.span>、
          <chakra.span color="moneiro-color-main-orange">iDeCo</chakra.span>と
          <chakra.span color="moneiro-color-main-orange">
            個人年金保険
          </chakra.span>
          で着実に運用
        </chakra.span>,
        <chakra.span key={2} textStyle={{ base: "16-bold" }}>
          <chakra.span color="moneiro-color-main-orange">
            安定した投資
          </chakra.span>
          を目指すため、預金は
          <chakra.span color="moneiro-color-main-orange">債券</chakra.span>
          を選択
        </chakra.span>,
      ],
    },
  },
  {
    customerProfile: {
      income: (
        <chakra.div>
          世帯年収
          <chakra.span
            color="moneiro-color-main-orange"
            textStyle={"20-bold"}
            fontFamily="roboto"
            mx={"2px"}
          >
            1000
          </chakra.span>
          万円
        </chakra.div>
      ),
      age: 37,
      displayName: "Kさん夫婦",
      type: "共働き",
      message: (
        <chakra.div whiteSpace={"pre"}>
          {`“将来の資産5200万円を目指しながら
   教育資金の積立もできました！”`}
        </chakra.div>
      ),
      img: {
        base: {
          src: staticPath_assets_case_case_02_png,
          width: 120,
          height: 120,
        },
        md: {
          src: staticPath_assets_case_case_02_png,
          width: 120,
          height: 120,
        },
      },
    },
    before: {
      amount: 2,
      method: "funded",
      graph: {
        base: {
          src: staticPath_assets_case_case_02_before_graph_png,
          width: 315,
          height: 118,
        },
        md: {
          src: staticPath_assets_case_case_02_before_graph_png,
          width: 370,
          height: 140,
        },
      },
      comments: ["毎月の貯蓄20万円を運用したい", "教育資金を積み立てたい"],
    },
    after: {
      graph: {
        base: {
          src: staticPath_assets_case_case_02_after_graph_png,
          width: 315,
          height: 178,
        },
        md: {
          src: staticPath_assets_case_case_02_after_graph_png,
          width: 370,
          height: 217,
        },
      },
      comments: [
        <chakra.span key={1} textStyle={{ base: "16-bold" }}>
          毎月の貯蓄は、
          <chakra.span color="moneiro-color-main-orange">NISA</chakra.span>、
          <chakra.span color="moneiro-color-main-orange">
            個人年金保険
          </chakra.span>
          、<chakra.span color="moneiro-color-main-orange">預金</chakra.span>
          に振り分け
        </chakra.span>,
        <chakra.span key={2} textStyle={{ base: "16-bold" }}>
          <chakra.span color="moneiro-color-main-orange">
            必要な教育資金
          </chakra.span>
          を試算して、
          <chakra.span color="moneiro-color-main-orange">積立金額</chakra.span>
          を決定
        </chakra.span>,
      ],
    },
  },
  {
    customerProfile: {
      income: (
        <chakra.div>
          年収
          <chakra.span
            color="moneiro-color-main-orange"
            textStyle={"20-bold"}
            fontFamily="roboto"
            mx={"2px"}
          >
            800
          </chakra.span>
          万円
        </chakra.div>
      ),
      age: 58,
      displayName: "Hさん",
      type: "既婚男性",
      message: (
        <chakra.div whiteSpace={"pre"}>
          {`“ゆとりある老後の生活を見据え、
   NISAの相談ができました！”`}
        </chakra.div>
      ),
      img: {
        base: {
          src: staticPath_assets_case_case_03_png,
          width: 120,
          height: 120,
        },
        md: {
          src: staticPath_assets_case_case_03_png,
          width: 120,
          height: 120,
        },
      },
    },
    before: {
      amount: 2,
      method: "funded",
      graph: {
        base: {
          src: staticPath_assets_case_case_03_before_graph_png,
          width: 315,
          height: 118,
        },
        md: {
          src: staticPath_assets_case_case_03_before_graph_png,
          width: 370,
          height: 140,
        },
      },
      comments: [
        "今後、住居購入を検討している",
        "毎月の積立資金15万円と預金700万円を活用したい",
      ],
    },
    after: {
      graph: {
        base: {
          src: staticPath_assets_case_case_03_after_graph_png,
          width: 315,
          height: 178,
        },
        md: {
          src: staticPath_assets_case_case_03_after_graph_png,
          width: 370,
          height: 217,
        },
      },
      comments: [
        <chakra.span key={1} textStyle={{ base: "16-bold" }}>
          <chakra.span color="moneiro-color-main-orange">一括投資</chakra.span>
          も組み合わせて、
          <chakra.span color="moneiro-color-main-orange">
            NISAの 成長枠投資
          </chakra.span>
          を最大限活用
        </chakra.span>,
        <chakra.span key={2} textStyle={{ base: "16-bold" }}>
          <chakra.span color="moneiro-color-main-orange">
            アクティブファンド
          </chakra.span>
          も組み合わせて
          <chakra.span color="moneiro-color-main-orange">
            老後資金の最大化
          </chakra.span>
          を目指す
        </chakra.span>,
      ],
    },
  },
]
