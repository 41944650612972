import { staticPath_assets_case_arrow_down_black_png } from "lib/$asset-path"
import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  AspectRatio,
  BoxProps,
  Button,
  CenterProps,
  chakra,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/react"
import { NextImage, NextImageInlineWrapper } from "components/system"

import NextLink from "next/link"
import { PropsWithChildren } from "react"
import { ConsultingCaseData } from "./data"

export type ConsultingCaseProps = {
  data: ConsultingCaseData
  withButtonLink?: boolean
  withHeader?: boolean
} & BoxProps

export const ConsultingCase = ({
  data,
  withButtonLink,
  withHeader,
  ...props
}: ConsultingCaseProps) => {
  const { kind, kindI18n, customerProfile, before, after } = data

  return (
    <VStack
      spacing={4}
      layerStyle="base"
      w="full"
      maxW="container.content"
      py={{ base: 6, md: 12 }}
      {...props}
    >
      {withHeader && <ConsultingCaseHeader title={kindI18n} />}
      <ConsultingCaseCustomerProfile customerProfile={customerProfile} />
      <ConsultingCaseBody before={before} after={after} />
      {withButtonLink && (
        <NextLink href={`/consulting/${kind}`} passHref legacyBehavior>
          <Button
            as="a"
            size="lg"
            colorScheme="secondary"
            rightIcon={<ChevronRightIcon boxSize="24px" />}
            fontSize={kind === "nisa" || kind === "insurance" ? "14px" : "16px"}
            w={{ base: "300px", md: "380px" }}
            pl={2}
            pr={0}
          >
            マネイロが{kindI18n}でできること
          </Button>
        </NextLink>
      )}
    </VStack>
  )
}

const ConsultingCaseHeader = ({ title }) => {
  return (
    <chakra.h3
      bgColor="moneiro-color-darkblue"
      w="full"
      color="white"
      textAlign={"center"}
      textStyle={{ base: "16-bold", md: "24-bold" }}
      py={{ base: 1, md: 2 }}
      borderTopLeftRadius="md"
      borderTopEndRadius="md"
      mt={{ base: "-30px", md: "-60px" }}
      mb={{ md: "15px" }}
    >
      {title}のご相談事例
    </chakra.h3>
  )
}

export const ConsultingCaseCustomerProfile = ({
  customerProfile,
  ...props
}: {
  customerProfile: ConsultingCaseData["customerProfile"]
} & CenterProps) => {
  const { income, img, message, age, type, displayName } = customerProfile

  return (
    <chakra.div
      w="full"
      h={{ base: "auto", md: "140px" }}
      display={{ base: "block", md: "flex" }}
      justifyItems={{ md: "center" }}
      pb={{ base: 4, md: 2 }}
      px={{ base: 4, md: 5 }}
      borderBottomWidth="2px"
      borderBottomColor="moneiro-color-main-orange"
      {...props}
    >
      <Stack
        spacing={{ base: 2, md: 4 }}
        display="flex"
        direction={{ base: "column", md: "row" }}
        align="center"
        justifyContent="space-around"
        w="full"
      >
        <chakra.div
          textStyle={{ base: "16-bold", md: "24-bold" }}
          color="moneiro-color-main-orange"
          maxW={{ md: "490px" }}
          pt={{ base: 3, md: 4 }}
        >
          {message}
        </chakra.div>
        <chakra.div
          display={"flex"}
          flexDir="row"
          gap={{ base: 5, md: 5 }}
          justifyItems={{ base: "center", md: "center " }}
          alignItems={{ base: "center", md: "center" }}
        >
          <NextImageInlineWrapper
            mb={-1}
            width={{ base: 81, md: 120 }}
            height={{ base: 81, md: 120 }}
            pos="relative"
          >
            <NextImage
              {...img.base}
              layout="fill"
              objectFit="contain"
              alt=""
              unoptimized
            />
          </NextImageInlineWrapper>
          <chakra.div
            textStyle={{ base: "12-reg", md: "14-reg" }}
            display="flex"
            flexDir={{ base: "column", md: "column" }}
            w={{ base: "auto", md: "168px" }}
            h={{ base: "auto", md: "88px" }}
            p="2px"
          >
            <chakra.div
              display="flex"
              flexDir={{ base: "row", md: "column" }}
              gap={{ base: "4px" }}
            >
              <chakra.div display="flex" flexDir={"row"} gap="4px">
                <chakra.div>{age}歳</chakra.div>
                <chakra.div>{type}</chakra.div>
              </chakra.div>
              <chakra.div>{displayName}</chakra.div>
            </chakra.div>
            {income}
          </chakra.div>
        </chakra.div>
      </Stack>
    </chakra.div>
  )
}

export const ConsultingCaseBody = ({
  before,
  after,
  ...props
}: {
  before: ConsultingCaseData["before"]
  after: ConsultingCaseData["after"]
} & StackProps) => {
  return (
    <Stack
      spacing={{ base: 4, md: 2 }}
      direction={"column"}
      w="full"
      {...props}
    >
      <Before before={before} />
      <Arrow />
      <After after={after} />
    </Stack>
  )
}

const Before = ({ before }: { before: ConsultingCaseData["before"] }) => {
  const { amount, method, graph, comments } = before

  return (
    <VStack spacing={2}>
      <chakra.div
        textStyle={{ base: "14-bold", md: "16-bold" }}
        bg="moneiro-color-fill-gray-sub"
        px={{ base: 2, md: "24px" }}
        py={{ base: 1, md: "4px" }}
        borderRadius="m"
        w="full"
        textAlign={{ base: "center", md: "left" }}
      >
        ご相談前
      </chakra.div>

      <chakra.div
        display="flex"
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: 3, md: 6 }}
        w="full"
        justifyContent={"space-between"}
      >
        <chakra.div display="flex" alignItems={"center"}>
          <chakra.ul w="full" pl="4">
            {comments.map((comment, idx) => {
              return <List key={idx}>{comment}</List>
            })}
          </chakra.ul>
        </chakra.div>
        <AspectRatio ratio={370 / 140} w="full" maxW={{ md: "370px" }}>
          <NextImageInlineWrapper
            mb={-1}
            width={{ base: 315, md: 370 }}
            height={{ base: 118, md: 140 }}
            pos="relative"
          >
            <NextImage
              src={graph.base.src}
              layout="fill"
              objectFit="contain"
              alt=""
              unoptimized
            />
          </NextImageInlineWrapper>
        </AspectRatio>
      </chakra.div>
    </VStack>
  )
}

const Arrow = () => {
  return (
    <>
      <NextImageInlineWrapper
        display={{ base: "block", md: "none" }}
        alignSelf="center"
      >
        <NextImage
          src={staticPath_assets_case_arrow_down_black_png}
          width={54}
          height={27}
          alt=""
        />
      </NextImageInlineWrapper>
      <NextImageInlineWrapper
        display={{ base: "none", md: "block" }}
        alignSelf="center"
      >
        <NextImage
          src={staticPath_assets_case_arrow_down_black_png}
          width={60}
          height={30}
          alt=""
        />
      </NextImageInlineWrapper>
    </>
  )
}

const After = ({ after }: { after: ConsultingCaseData["after"] }) => {
  const { graph, comments } = after

  return (
    <VStack spacing={2}>
      <chakra.div
        textStyle={{ base: "14-bold", md: "16-bold" }}
        bg="moneiro-color-fill-pale-yellow"
        px={{ base: 2, md: "24px" }}
        py={{ base: 1, md: "4px" }}
        borderRadius="m"
        w="full"
        textAlign={{ base: "center", md: "left" }}
      >
        ご相談後
      </chakra.div>

      <chakra.div
        display="flex"
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: 3, md: 6 }}
        w="full"
        justifyContent={"space-between"}
      >
        <chakra.div display="flex" alignItems={"center"}>
          <chakra.ul w="full" pl="4">
            {comments.map((comment, idx) => {
              return <List key={idx}>{comment}</List>
            })}
          </chakra.ul>
        </chakra.div>
        <AspectRatio ratio={370 / 217} w="full" maxW={{ md: "370px" }}>
          <NextImageInlineWrapper
            mb={-1}
            width={{ base: graph.base.width, md: graph.md.width }}
            height={{ base: graph.base.height, md: graph.md.height }}
            pos="relative"
          >
            <NextImage
              src={graph.base.src}
              layout="fill"
              objectFit="contain"
              alt=""
              unoptimized
            />
          </NextImageInlineWrapper>
        </AspectRatio>
      </chakra.div>
    </VStack>
  )
}

const List = ({
  children,
  after,
}: PropsWithChildren<{
  after?: boolean
}>) => {
  return (
    <chakra.li
      pl={1}
      pb={{ base: 1, md: 2 }}
      mb={{ base: 1, md: 2 }}
      textStyle={{ base: "14-reg", md: "16-reg" }}
      color={after ? "moneiro-color-main-orange" : "moneiro-color-text-black"}
      borderBottom="1px"
      borderBottomColor="moneiro-color-line-gray-strong"
    >
      {children}
    </chakra.li>
  )
}
