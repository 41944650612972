/** imgixのリサイズ等を行う
 * 今のところ何も便利ではないけど型チェックなど入れたい
 *
 * ここでパラメーターは作れます
 * - https://sandbox.imgix.com/view?url=https%3A%2F%2Fassets.imgix.net%2Funsplash%2Fwalrus.jpg
 * - ドキュメント: https://docs.imgix.com/apis/rendering
 */
export const imgixURL = <T extends string | undefined | null = string>(
  imgixURL: T,
  operation: Operations | null,
  optimize = true,
  /** 既に付与されているパラメーターを削除する */
  clear = false
): T => {
  if (!imgixURL) return imgixURL
  let current: string = clear ? processBase(imgixURL) : imgixURL
  if (operation) {
    current += `${current.indexOf("?") === -1 ? "?" : "&"}${operation}`
  }
  if (optimize && current.indexOf("auto=") === -1) {
    current += `${current.indexOf("?") === -1 ? "?" : "&"}auto=compress,format`
  }
  return current as T
}

type Operations = string

const processBase = (src: string) => src.replace(/\?.*/, "")
