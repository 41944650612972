import { MetaTag } from "@~/common/components"
import { PublicBaseURL } from "@~/common/lib/constants"
import { CMSClient, Entry } from "@~/common/lib/microcms/media"
import { createGetAppPageLayout } from "components/layout/AppPageLayout"
import { Top } from "components/top"
import { setPageAttr } from "lib/page"
import { getSiteInfo, SiteInfo } from "lib/server/siteinfo"
import { GetStaticProps } from "next"

const IndexPage = (props: PageProps) => {
  return (
    <>
      <MetaTag
        title="マネイロ"
        titleSuffix="｜はたらく世代のお金の診断・相談サービス"
        description="マネイロは、30-40代を中心とするはたらく世代のお金の診断・相談サービスです。あなたが将来いくら必要なのか、そのためにどんな投資がオススメなのかを、スマホで簡単に診断。困ったときはお金のプロに無料で相談できます。"
        canonical={PublicBaseURL}
      />
      <Top {...props} />
    </>
  )
}

setPageAttr(IndexPage, { noAuth: true, getLayout: createGetAppPageLayout() })

export default IndexPage

export type PageProps = {
  media: {
    data: Pick<
      Entry,
      "id" | "title" | "publishedAt" | "tag" | "eyecatch" | "createdAt"
    >[]
  }
  siteInfo: SiteInfo
}

export const getStaticProps: GetStaticProps<PageProps> = async (_ctx) => {
  const mediaPromise = CMSClient.current.listItems("entry", {
    limit: 3,
    offset: 0,
    fields: ["id", "title", "publishedAt", "tag", "eyecatch", "createdAt"],
    orders: "-publishedAt",
  })

  const siteInfoPromise = getSiteInfo("top", 15 * 60 * 1000)

  const [mediaData, siteInfoData] = await Promise.all([
    mediaPromise,
    siteInfoPromise,
  ])

  return {
    props: {
      media: {
        data: mediaData.contents,
      },
      siteInfo: siteInfoData,
    },
    revalidate: 30 * 60,
  }
}
