import { Box, BoxProps, ColorProps } from "@chakra-ui/react"
import React from "react"

export type RibbonProps = React.PropsWithChildren<
  {
    ribonColor?: ColorProps["color"]
    ribonWidth?: number
    ribonHeight?: {
      base: number
      decoration: number
    }
    triangleWidth?: number
    triangleHeight?: number
  } & Omit<BoxProps, "_before" | "_after">
>

export const Ribbon = ({
  children,
  ribonColor = "moneiro-color-yellow",
  ribonWidth = 40,
  ribonHeight = {
    base: 50,
    decoration: 10,
  },
  triangleWidth = 10,
  triangleHeight = 10,
  ...props
}: RibbonProps) => {
  return (
    <Box
      bgColor={ribonColor}
      pos="relative"
      _before={{
        content: `''`,
        borderRight: `solid ${triangleWidth}px transparent`,
        borderBottom: `solid ${triangleHeight}px`,
        borderBottomColor: ribonColor,
        position: "absolute",
        top: 0,
        right: `-${triangleWidth}px`,
      }}
      _after={{
        content: `''`,
        borderX: `solid ${ribonWidth / 2}px`,
        borderRightColor: ribonColor,
        borderLeftColor: ribonColor,
        borderBottom: `solid ${ribonHeight.decoration}px transparent`,
        position: "absolute",
        top: "100%",
        left: 0,
      }}
      {...props}
      w={`${ribonWidth}px`}
      h={`${ribonHeight.base}px`}
    >
      {children}
    </Box>
  )
}
