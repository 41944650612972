import {
  staticPath_assets_lp_top_logo_newspicks_png,
  staticPath_assets_lp_top_logo_jwave_png,
  staticPath_assets_lp_top_logo_amexpo_png,
  staticPath_assets_lp_top_logo_forbes_png,
  staticPath_assets_lp_top_logo_thebridge_png,
  staticPath_assets_lp_top_logo_hiruobi_png,
  staticPath_assets_lp_top_logo_news_dig_png,
} from "lib/$asset-path"
import {
  Box,
  BoxProps,
  chakra,
  StackProps,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { NextImage, NextImageProps } from "components/system"

export type MediaCoverageListProps = StackProps

export const MediaCoverageList = ({ ...props }: MediaCoverageListProps) => {
  return (
    <VStack spacing={{ base: 1, md: 3 }} py={8} as="section" {...props}>
      <chakra.h2
        id="media-coverage-list"
        fontWeight={700}
        fontSize="20px"
        lineHeight="28px"
        letterSpacing="0.065em"
        textAlign="center"
        color="text.black"
      >
        メディア掲載実績
      </chakra.h2>
      <Wrap
        justify="center"
        align="center"
        spacing={{ base: 1, md: 3 }}
        maxW={{ base: "300px", md: "full" }}
      >
        {mediaCoverageListData.map(
          ({ label, href, title, img, width, height }) => (
            <WrapItem
              key={label}
              justifyContent="center"
              alignItems="center"
              minH="44px" // クリッカブルなスペースを確保
              {...(href
                ? {
                    as: "a",
                    href,
                    title,
                    target: "_blank",
                    rel: "noopener",
                  }
                : undefined)}
            >
              <Box width={width} height={height}>
                <NextImage layout="intrinsic" alt={label} {...img} />
              </Box>
            </WrapItem>
          )
        )}
      </Wrap>
    </VStack>
  )
}

type MediaCoverageListData = {
  label: string
  href?: string
  title?: string
  img: NextImageProps
  width: BoxProps["width"]
  height: BoxProps["height"]
}

const mediaCoverageListData: MediaCoverageListData[] = [
  {
    label: "NEWS PICKS",
    img: {
      src: staticPath_assets_lp_top_logo_newspicks_png,
      width: 120 / 2,
      height: 120 / 2,
    },
    width: { base: "40px", md: "59px" },
    height: { base: "40px", md: "59px" },
  },
  {
    label: "J-WAVE 81.3FM",
    href: "https://moniclefinancial.co.jp/news/L_Mnt0Ji",
    title:
      "ラジオ出演のお知らせ～弊社取締役瓜田雅和がJ-WAVEに出演いたしました！～",
    img: {
      src: staticPath_assets_lp_top_logo_jwave_png,
      width: 104 / 2,
      height: 120 / 2,
    },
    width: { base: "35px", md: "52px" },
    height: { base: "40px", md: "60px" },
  },
  {
    label: "資産運用 EXPO",
    href: "https://moniclefinancial.co.jp/news/G-eAyk8z",
    title: "『資産運用EXPO』に初出展~立ち見客も！セミナーは連日満員御礼！~",
    img: {
      src: staticPath_assets_lp_top_logo_amexpo_png,
      width: 253 / 2,
      height: 48 / 2,
    },
    width: { base: "85px", md: `${253 / 2}px` },
    height: { base: "16px", md: `${48 / 2}px` },
  },
  {
    label: "Forbes JAPAN",
    href: "https://forbesjapan.com/articles/detail/30571",
    title:
      "月2万円で、老後に3000万円の資産を形成──プロが厳選した資産運用プランを提供するサービスが登場 | Forbes JAPAN（フォーブス ジャパン）",
    img: {
      src: staticPath_assets_lp_top_logo_forbes_png,
      width: 155 / 2,
      height: 60 / 2,
    },
    width: { base: "52px", md: `${155 / 2}px` },
    height: { base: "20px", md: `${60 / 2}px` },
  },
  {
    label: "BRIDGE",
    href: "https://thebridge.jp/2019/11/onemile-partners-seed-round-funding",
    title:
      "「資産のセレクトショップ」運営を目指すOneMile Partners、シードラウンドで約1.3億円を資金調達 | BRIDGE",
    img: {
      src: staticPath_assets_lp_top_logo_thebridge_png,
      width: 193 / 2,
      height: 48 / 2,
    },
    width: { base: "65px", md: `${193 / 2}px` },
    height: { base: "16px", md: `${48 / 2}px` },
  },
  {
    label: "ひるおび",
    href: "https://moniclefinancial.co.jp/news/5kjHlUL_",
    img: {
      src: staticPath_assets_lp_top_logo_hiruobi_png,
      width: 138 / 2,
      height: 48 / 2,
    },
    width: { base: "54px", md: `${138 / 2}px` },
    height: { base: "19px", md: `${48 / 2}px` },
  },
  {
    label: "TBS NES DIG",
    href: "https://newsdig.tbs.co.jp/articles/-/60574",
    img: {
      src: staticPath_assets_lp_top_logo_news_dig_png,
      width: 94 / 2,
      height: 108 / 2,
    },
    width: { base: "35px", md: `${94 / 2}px` },
    height: { base: "40px", md: `${108 / 2}px` },
  },
]
