import {
  AspectRatio,
  AspectRatioProps,
  chakra,
  HTMLChakraProps,
} from "@chakra-ui/react"

export type ServiceIntroductionMovieProps = {
  autoplay?: boolean
  _iframe?: HTMLChakraProps<"iframe">
} & AspectRatioProps

export const ServiceIntroductionMovie = ({
  autoplay = false,
  _iframe,
  ...props
}: ServiceIntroductionMovieProps) => {
  const q = autoplay ? "?autoplay=1&mute=1" : ""

  return (
    <AspectRatio ratio={16 / 9} {...props}>
      <chakra.iframe
        title="90秒でわかる「マネイロ」"
        src={`https://www.youtube.com/embed/GkEkbIXlfIc${q}`}
        allowFullScreen
        {..._iframe}
      />
    </AspectRatio>
  )
}
