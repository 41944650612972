import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Center,
  chakra,
} from "@chakra-ui/react"
import { News } from "lib/cms/type"
import { isNumber } from "typesafe-utils"

export const NewsSection = ({
  news,
  colorScheme = "gray",
  ...props
}: { news: News[]; colorScheme?: "gray" | "light" } & Omit<
  AccordionProps,
  "colorScheme"
>) => {
  if (news.length === 0) return null
  return (
    <chakra.aside
      bgColor={{ gray: "bg.gray2", light: "white" }[colorScheme]}
      pt={4}
      pb={3}
      px={3}
    >
      <Accordion
        allowMultiple
        defaultIndex={news
          .map((n, i) => (n.urgent ? i : false))
          .filter(isNumber)}
        {...props}
      >
        {news.map((news) => (
          <AccordionItem border="0" textStyle="note" key={news.id}>
            <chakra.h2>
              <AccordionButton
                display="flex"
                flexDirection="column"
                alignItems="center"
                px={3}
                pb={0}
                _hover={{ bgColor: "inherit" }}
                textAlign="left"
              >
                <chakra.div display="flex" alignItems="center">
                  <NewsLabel type={news.urgent ? "urgent" : "important"} />
                  <chakra.span textStyle="f14Bold" textDecor="underline">
                    {news.title}
                  </chakra.span>
                </chakra.div>
                <Center>
                  <AccordionIcon />
                </Center>
              </AccordionButton>
            </chakra.h2>
            <AccordionPanel px={3}>
              {news.message && (
                // あとで https://blog.microcms.io/richeditorformat/ に変更する
                <chakra.div
                  sx={{
                    a: { textDecor: "underline" },
                  }}
                  textStyle="f14"
                  dangerouslySetInnerHTML={{ __html: news.message }}
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </chakra.aside>
  )
}
const NewsLabel = (props: { type: "important" | "urgent" }) => {
  const options = {
    important: { text: "重要", props: { color: "text.mainDark" } },
    urgent: { text: "緊急", props: { color: "text.required" } },
  }
  const option = options[props.type]
  return (
    <chakra.span
      textStyle="f12Bold"
      mr={2}
      _before={{ content: `"【"` }}
      _after={{ content: `"】"` }}
      whiteSpace="nowrap"
      {...option.props}
    >
      {option.text}
    </chakra.span>
  )
}
