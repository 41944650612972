import { staticPath_assets_top_movie_thumbnail_png } from "lib/$asset-path"
import { Box, chakra } from "@chakra-ui/react"
import { ServiceIntroductionMovie } from "components/lp/widgets/ServiceIntroductionMovie"
import { NextImage } from "components/system"

import React from "react"

export type MovieProps = {}

export const Movie = React.forwardRef<HTMLElement>(
  ({ ...props }: MovieProps, ref) => {
    const [displayThumbnail, setDisplayThumbnail] = React.useState(true)
    const displayMovie = React.useCallback(() => {
      setDisplayThumbnail(false)
    }, [])

    return (
      <chakra.section
        ref={ref}
        bgColor="moneiro-color-fill-pale-yellow"
        px={{ base: 3, md: 0 }}
        pt={{ base: 6, md: 10 }}
        pb={{ base: 4, md: 0 }}
      >
        <chakra.h2
          id="movie"
          textStyle="18-bold"
          textAlign="center"
          color="moneiro-color-text-black"
          mb={{ base: 4, md: 6 }}
        >
          はたらく世代向け
          <br />
          資産運用サービス「マネイロ」とは
        </chakra.h2>
        {displayThumbnail ? (
          <Box
            onClick={displayMovie}
            maxW="680px"
            mx="auto"
            _hover={{ cursor: "pointer" }}
          >
            <NextImage
              src={staticPath_assets_top_movie_thumbnail_png}
              width={680}
              height={383}
              alt="90秒でわかる「マネイロ」動画サムネイル"
            />
          </Box>
        ) : (
          <ServiceIntroductionMovie autoplay maxW="680px" mx="auto" />
        )}
      </chakra.section>
    )
  }
)
Movie.displayName = "Movie"
